import React from 'react'
import Layout from '../components/Layout/Layout'
import SquaresSection from '../components/_shared/SquaresSection/SquaresSection'
import TextSection from '../components/_shared/TextSection/TextSection'
import ReferenceSection from '../components/_shared/ReferenceSection/ReferenceSection'
import ContactFormSection from '../components/_shared/ContactFormSection/ContactFormSection'

const PartnersPage = () => (
  <Layout pageTitle="Partnerzy" headerImage={2}>
    <div className="container container--page">
      <TextSection contentText="Lipus Safety współpracuje tylko z renomowanymi producentami artykułów BHP. Dzięki temu możemy zaoferować naszym klientom asortyment najwyższej jakości, spełniający niezbędne normy, a zarazem wyróżniający się atrakcyjnymi cenami.<br/><br/>Oferujemy produkty pochodzące od liderów branży BHP – zarówno cenionych i znanych firm krajowych, jak i zagranicznych. Decydując się na nasze usługi wybierasz najwyższą jakość, innowacyjność i gwarancję niezawodności.<br/><br/>Stale poszerzamy asortyment naszych produktów, śledząc rozwój rynku BHP. Wszystko po to, aby zaspokoić potrzeby nawet najbardziej wymagających klientów." />
      <SquaresSection />
    </div>
    <ReferenceSection />
    <ContactFormSection />
  </Layout>
)

export default PartnersPage
