import React from 'react'
import './ReferenceSection.scss'

const ReferenceSection = () => (
  <div className="referenceSection">
    <div className="container container--referenceSection">
      <span className="referenceSection__text">
        „Podstawowymi wartościami jakimi kierujemy się we współpracy z naszymi partnerami to
        wzajemny szacunek, zaufanie, uczciwość oraz dobra komunikacja. Wartości te ugruntowują i
        dają możliwość realizowania się na danej płaszczyźnie. Z nimi dążenie do wybranych celów
        jest nie tyle łatwiejsze, co bardziej satysfakcjonujące.”
      </span>
      <span className="referenceSection__name">LIPUS SAFETY</span>
    </div>
  </div>
)

export default ReferenceSection
