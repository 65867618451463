import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import './SquaresSection.scss'
import ImageSection from '../ImageSection/ImageSection'

const SquaresSection = () => {
  const data = useStaticQuery(graphql`{
  img1: file(relativePath: {eq: "partners/1.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img2: file(relativePath: {eq: "partners/2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img4: file(relativePath: {eq: "partners/4.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img5: file(relativePath: {eq: "partners/5.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img6: file(relativePath: {eq: "partners/6.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img7: file(relativePath: {eq: "partners/7.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img8: file(relativePath: {eq: "partners/8.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img9: file(relativePath: {eq: "partners/9.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img10: file(relativePath: {eq: "partners/10.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
  img11: file(relativePath: {eq: "partners/11.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 220, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <div className="squaresSection">
      <div className="squaresSection__list">
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="W+R"
            imageFluid={data.img1.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="3M"
            imageFluid={data.img2.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="Beta"
            imageFluid={data.img4.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="DeltaPlus"
            imageFluid={data.img5.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="KegelBlazusiak"
            imageFluid={data.img6.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="JSP"
            imageFluid={data.img7.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="PPO"
            imageFluid={data.img8.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="Protekt"
            imageFluid={data.img9.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="Singer"
            imageFluid={data.img10.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="squaresSection__square">
          <ImageSection
            sectionClass="squaresSection__image"
            alt="UVEX"
            imageFluid={data.img11.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
    </div>
  );
}

export default SquaresSection
